import React, { useState } from 'react'
import logo from '../images/weberz.png'
import { Link } from 'react-router-dom'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

function Nav() {

    const [dropdown, setDropDown] = useState(false)
    return (
        <nav className="w-full sectionServices h-fit  md:px-64  py-5 px-3  flex items-center justify-between">
            <Link to="/" className="md:flex hidden items-center justify-start">
                <img src={logo} className="h-[1.5rem] md:h-[2rem]" alt="Scalex" />
            </Link>
            <div className="md:flex hidden items-center z-40 gap-14 justify-center">
                <Link to="/" className="text-neutral-800 scroll-link cursor-pointer font-[500] transition-all duration-100 hover:border-b-[3.5px]   py-2 hover:border-black leading-[20px] uppercase text-[16px]">Home </Link>
                <a href="#WhyWeberz" className="text-neutral-800 scroll-link cursor-pointer font-[500] transition-all duration-100 hover:border-b-[3.5px]   py-2 hover:border-black leading-[20px] uppercase text-[16px]">About Us </a>
                <a href="#Benifits" className="text-neutral-800 scroll-link cursor-pointer  font-[500] transition-all duration-100 hover:border-b-[3.5px]   py-2 hover:border-black leading-[20px] uppercase  text-[16px]">benifits</a>
                <Popover>
                    <PopoverButton className="text-neutral-800 flex items-center justify-center gap-2 scroll-link cursor-pointer  font-[500] transition-all duration-100 hover:border-b-[3.5px]   py-2 hover:border-black leading-[20px] uppercase outline-none text-[16px] data-[active]:text-neutral-800 data-[hover]:text-neutral-800 ">
                        Services
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>


                    </PopoverButton>
                    <PopoverPanel
                        transition
                        anchor="bottom"
                        className="divide-y divide-white/5 rounded-xl bg-gray-50 shadow text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] z-50 data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                    >
                        <div className="p-3">
                            <Link to='/' className="block rounded-lg py-2 px-3 transition hover:bg-black/5" href="#">
                                <p className="font-semibold text-neutral-800">Agency Ad Accounts</p>
                                <p className="text-neutral-800/50">Measure actions your users take</p>
                            </Link>
                            <Link className="block rounded-lg py-2 px-3 transition hover:bg-black/5" href="#">
                                <p className="font-semibold text-neutral-800">Payment gateways</p>
                                <p className="text-neutral-800/50">Create your own targeted content</p>
                            </Link>
                            <Link className="block rounded-lg py-2 px-3 transition hover:bg-black/5" href="#">
                                <p className="font-semibold text-neutral-800">Facebook Assets</p>
                                <p className="text-neutral-800/50">Keep track of your growth</p>
                            </Link>
                            <Link className="block rounded-lg py-2 px-3 transition hover:bg-black/5" href="#">
                                <p className="font-semibold text-neutral-800">Social Media Services</p>
                                <p className="text-neutral-800/50">Keep track of your growth</p>
                            </Link>
                        </div>

                    </PopoverPanel>
                </Popover>                <a href="#FAQ" className="text-neutral-800 scroll-link cursor-pointer  font-[500] transition-all duration-100 hover:border-b-[3.5px]   py-2 hover:border-black leading-[20px] uppercase text-[16px]">faq</a>
            </div>
            <div className="md:flex hidden items-center justify-end">
                <a href="#form " className="text-neutral-800 hover:scale-105 scroll-link cursor-pointer  uppercase font-[700] leading-[22px] hover:bg-white hover:text-gray-900  text-[17px] flex items-center justify-center w-fit border border-neutral-800 py-3 px-10 transition-all duration-300">
                    Get started
                </a>
            </div>
             <div className="text-neutral-800 md:hidden flex justify-end items-center">
                {/* Toggle Button */}
                <button onClick={() => setDropDown(!dropdown)} className="focus:outline-none z-50">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
                {/* Dropdown Menu */}
                {
                    dropdown &&

                    <div className="absolute top-[4.5rem] bg-white right-0 flex-col flex w-full justify-start items-center overflow-hidden   duration-300 ease-in-out">
                        <Link to="/" className="text-neutral-800 scroll-link cursor-pointer font-[500] transition-all duration-100 hover:border-b-[3.5px]   py-2 hover:border-black leading-[20px] uppercase text-[16px]">Home </Link>

                        <a href="#WhyWeberz" className="text-neutral-700 scroll-link cursor-pointer font-[500] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[16px]">why
                            weberz</a>
                        <a href="#Benifits" className="text-neutral-700 scroll-link cursor-pointer font-[500] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[16px]">benifits</a>
                        <a href="#Services" className="text-neutral-700 scroll-link cursor-pointer font-[500] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[16px]">services</a>
                        <a href="#FAQ" className="text-neutral-700 scroll-link cursor-pointer font-[500] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[16px]">faq</a>
                    </div>
                }

            </div>

             <Link to="/" className="flex md:hidden items-center justify-start">
                <img src={logo} className="h-[1.5rem] md:h-[2rem]" alt="Scalex" />
            </Link>
              <a href='#form' className='md:hidden'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>

            </a>
           
        </nav>)
}

export default Nav