import React, { useEffect } from 'react';
import photo from '../images/1.png';

function Form() {
  useEffect(() => {
    const scriptURL = 'https://script.google.com/macros/s/AKfycbwKozyruGAaOG5eYkGWtSSJcE2ePLlIclAO3bLR7dU5uyPvu-Vlo3_EiFt1rAoUZh-i/exec';
    const form = document.forms['submit-to-google-sheet'];
    const loadingDiv = document.getElementById('loadingDiv');
    const sumbmitText = document.getElementById('sumbmitText');
    const message = document.getElementById('message');

    const handleSubmit = async (e) => {
      e.preventDefault();
      sumbmitText.classList.add('hidden');
      loadingDiv.classList.remove('hidden');
      try {
        const response = await fetch(scriptURL, {
          method: 'POST',
          body: new FormData(form),
        });

        if (response.ok) {
          console.log('Success!', response);
          form.reset();
          message.classList.remove('hidden');
        } else {
          console.error('Error!', response.statusText);
        }
      } catch (error) {
        console.error('Error!', error.message);
      } finally {
        sumbmitText.classList.remove('hidden');
        loadingDiv.classList.add('hidden');
      }
    };

    form.addEventListener('submit', handleSubmit);

    // Cleanup event listener on component unmount
    return () => {
      form.removeEventListener('submit', handleSubmit);
    };
  }, []);
    return (

      <section id="form" className="w-full h-full py-14 px-3 md:py-24 relative bg-black">
  {/* Overlay */}
  {/* <div class="absolute inset-0 bg-black z-10 opacity-60"></div> */}
  {/* Background Image */}
  {/* <div class="absolute inset-0 bg-cover bg-center" style="background-image: url('./images/moon.jpg');">
      </div> */}
  {/* Content */}
  <div className="relative flex md:flex-row flex-col gap-12 md:gap-24 justify-center items-center md:px-24 z-10 w-full h-full">
    <div className="flex justify-center gap-7 items-center  w-full h-full flex-col ">
      <div className="flex items-center  justify-start">
        <img src={photo} className="md:h-[23rem] h-[15rem]" alt="Scalex" />
      </div>
      <h1 className="font-[800] text-center  capitalize leading-[44px] text-[37px]  text-gray-100">Let's Get started 
      </h1>
      <h4 className="font-[400]  text-center uppercase leading-[26px] text-[17px] text-gray-100">
The Weberz team will contact you and complete the onboarding process in less than 12 hours, ensuring your ads are scaled without bans or limits

      </h4>
    </div>
    <div className="flex justify-center w-full items-center">
      <form name="submit-to-google-sheet" action=" " className=" w-full h-fit   rounded-xl  shadow-xl sm:p-6 p-3 bg-gray-200">
        <div className="w-full p-2 h-full grid gap-4">
          <div className="flex  mb-3 text-neutral-800 text-[26px]  md:text-[37px] font-[700] justify-center items-center ">
            Get started with us
          </div>
          <div id="message" className="border hidden flex justify-center items-center border-green-600 text-green-600 font-[600] text-[14px] bg-green-100  rounded-full w-full h-14">
            Info sent successfully !
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 w-full">
            <div className="flex justify-center flex-col items-start gap-2">
              <label htmlFor className="text-neutral-800 text-[16px] font-[600]">Full Name *</label>
              <input type="text" placeholder="Full Name .." name="Nom Complet" className=" px-6 outline-none  rounded-full border-b-2 border-black h-12 w-full text-[14px] font-[500] " required />
            </div>
            <div className="flex justify-center flex-col items-start gap-2">
              <label htmlFor className="text-neutral-800 text-[16px] font-[600]">Email *</label>
              <input type="text" placeholder="Email address ..." name="Email" className="px-6 outline-none  rounded-full border-b-2 border-black h-12 w-full text-[14px] font-[500] " required />
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 w-full">
            <div className="flex justify-center flex-col items-start gap-2">
              <label htmlFor className="text-neutral-800 text-[16px] font-[600]">Platform *</label>
              <select name="Platform" className="px-6 outline-none  rounded-full border-b-2 border-black h-12 w-full text-[14px] font-[500] ">
                <option selected>Choose Platform</option>
                <option value="Facebook">Facebook</option>
                <option value="Snapchat">Snapchat</option>
                <option value="Google">Google</option>
                <option value="Tiktok">Tiktok</option>
              </select>
            </div>
            <div className="flex justify-center flex-col items-start gap-2">
              <label htmlFor className="text-neutral-800 text-[16px] font-[600]">What's your monthly
                spend
                *</label>
              <select name="Platform" className="px-6 outline-none  rounded-full border-b-2 border-black h-12 w-full text-[14px] font-[500] ">
                <option selected>Choose Monthly spend</option>
                <option value="$0k - $15k">$0k - $15k</option>
                <option value="$15k - $40k">$15k - $50k</option>
                <option value="$50k - $100k">$50k - $150k</option>
                <option value="+$100k">+$150k</option>
              </select>
            </div>
          </div>
          <div className="grid  md:grid-cols-2 gap-5 w-full">
            <div className="flex justify-center flex-col items-start gap-2">
              <label htmlFor className="text-neutral-800 text-[16px] font-[600]">Company Name *</label>
              <input type="text" placeholder="Company Name" name="CompanyName" className="px-6 outline-none  rounded-full border-b-2 border-black h-12 w-full text-[14px] font-[500]" required />
            </div>
            <div className="flex justify-center flex-col items-start gap-2">
              <label htmlFor className="text-neutral-800 text-[16px] font-[600]">WhatsApp Number *</label>
              <input type="text" placeholder="WhatsApp Number ..." name="PhoneNumber" className="px-6 outline-none  rounded-full border-b-2 border-black h-12 w-full text-[14px] font-[500] " required />
            </div>
          </div>
           
        </div>
        <div className="flex w-full justify-center items-center my-6 ">
          <button className=" w-full px-6 mx-2 rounded-full   h-12 text-[16px] bg-black text-white font-[600] ">
            <span id="sumbmitText">Submit</span>
            <div id="loadingDiv" className="inline-block h-4 w-4 hidden animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
            </div>
          </button>
        </div>
      </form></div>
  </div>
</section>
 )
}

export default Form