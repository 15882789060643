import React from 'react'

function TermsofService() {
    return (
        <div className='flex flex-col sectionServices w-full md:px-40 px-3 py-20 gap-5 '>
            <h1 className='text-[30px] font-[700]'>Terms of Service</h1>
            <p>Welcome to the website operated by Weberz, Weberz Corp, Weberz LLC, and Weberz LTD (collectively referred to as “WEBERZ,” “we,” “our,” or “us”). By accessing and using our website (the “Site”), you agree to the following terms and conditions (the “Terms”). Please review these Terms carefully. We may update these Terms from time to time without prior notice, so please check back periodically. Your continued use of the Site after any changes to these Terms constitutes your acceptance of the revised Terms.</p>
            <b>1. Description of Service</b>
            <p>This Site provides a variety of resources and information for users to find, engage, and manage expert networks (the “Services”). The Site does not provide medical, investment, or legal advice, and no attorney-client relationship is created between users of the Services and WEBERZ or its licensors.</p>
            <b>2. User Content</b>
            <p>Some of the content on this Site, including but not limited to text, software, scripts, graphics, and videos (collectively, “Site Content”), may be created and provided by users or third parties. WEBERZ does not endorse and is not responsible for any user-generated content. All user-generated content is provided “as is” without any warranties or representations as to its accuracy, reliability, or completeness.</p>
            <b>3. Confidentiality</b>
            <p>Users agree not to disclose or use any confidential information obtained through the Site, except as expressly authorized. Confidential information includes, but is not limited to, any proprietary data or information not publicly available. If required by law to disclose such information, you agree to notify WEBERZ promptly and cooperate in any effort to protect such information.</p>
            <b>4. Intellectual Property Rights</b>
            <p>All Site Content and trademarks, service marks, and logos (collectively, “Marks”) are owned by or licensed to WEBERZ. You may not copy, reproduce, distribute, or otherwise use any Site Content or Marks without the prior written consent of the respective owner.</p>
            <b>5. Reporting Concerns</b>
            <p>If you believe that any content on the Site violates these Terms or is otherwise unlawful, please contact WEBERZ at the following:</p>
            <p>Email: <a href="mailto:Contact@weberz-agency.com">Contact@weberz-agency.com</a></p>
            <p>Address: 312 W 2ND ST STE 3625, CASPER, WY 82601, US</p>
            <b>6. Copyright Policy</b>
            <p>WEBERZ prohibits the posting of content that infringes or violates the intellectual property rights of others. If you believe your intellectual property rights are being infringed, please send a written notice to WEBERZ containing the following:</p>
            <ul>
                <li>Identification of the copyrighted work or intellectual property right claimed to be infringed;</li>
                <li>Identification of the allegedly infringing material on the Site;</li>
                <li>Your contact information;</li>
                <li>A statement that you have a good faith belief that the use of the material is not authorized;</li>
                <li>A statement that the information in the notice is accurate;</li>
                <li>Your signature or an electronic equivalent.</li>
            </ul>
            <b>7. Privacy Policy</b>
            <p>Your use of the Site is subject to our Privacy Policy. By using the Site, you consent to the collection, use, and storage of your information as described in the Privacy Policy.</p>
            <b>8. Disclaimer of Warranties</b>
            <p>The Site is provided “as is” without any warranties, express or implied, including but not limited to implied warranties of merchantability or fitness for a particular purpose. WEBERZ does not guarantee the accuracy, reliability, or availability of the Site or its content.</p>
            <b>9. Indemnification</b>
            <p>You agree to indemnify and hold harmless WEBERZ from any claims, damages, or expenses arising from your use of the Site or violation of these Terms.</p>
            <b>10. Third-Party Sites</b>
            <p>The Site may contain links to third-party websites. WEBERZ is not responsible for the content or practices of such websites and does not endorse them.</p>
            <b>11. General Terms</b>
            <p><b>Governing Law; Jurisdiction:</b> These Terms are governed by the laws of the State of Wyoming. Any disputes arising from these Terms will be handled in the courts located within the State of Wyoming.</p>
            <p><b>Complete Agreement:</b> These Terms constitute the entire agreement between you and WEBERZ and supersede all prior agreements.</p>
            <p><b>Severability:</b> If any provision of these Terms is found to be unenforceable, the remaining provisions will continue in full force and effect.</p>
        </div>

    )
}

export default TermsofService