import React, { useState } from 'react';

function Faq() {
    const [openAnswerIndex, setOpenAnswerIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenAnswerIndex(openAnswerIndex === index ? null : index);
    };

    return (
        <section id="FAQ" className="w-full sectionServices flex justify-center items-center gap-4 md:py-24 py-10 px-3 relative bg-gray-50">
            <div className="flex flex-col justify-center md:gap-24 gap-12 items-center w-full md:w-2/3">
                <div className="flex justify-center gap-10 items-center w-full h-full flex-col">
                    <h1 className="font-[900] text-center uppercase leading-[69px] text-[112px] text-neutral-800">FAQ</h1>
                    <h4 className="font-[400] text-center uppercase leading-[26px] text-[17px] text-neutral-800">
Have a question about Weberz? We're here to help! Book a call to get all the details you need                    </h4>

                </div>
                <div className="w-full grid gap-5 md:grid-cols-2">
                    {[
                        {
                            question: "How do I become a Weberz's client?",
                            answer: "Complete the application form, and a dedicated team member will promptly reach out to guide you through the onboarding process. "
                        },
                        {
                            question: "Do you only provide agency ad accounts?",
                            answer: "Yes, we work with all types of businesses, including those with more aggressive marketing strategies. Our policy experts will review your ads to ensure full compliance. With clients from a wide range of industries, feel free to reach out, and we'll assess if we're the right fit for your needs."
                        },
                        {
                            question: "Do you work with all types of businesses?",
                            answer: "Our Meta specialists will implement an advanced account structure designed to prevent bans. Even in the case of a permanent ban, our system ensures your advertising continues seamlessly without interruption"
                        },
                        {
                            question: "How many agency ad accounts can I get?",
                            answer: "Weberz is fully committed to your success. If you're not completely satisfied with our service, please contact us, and we’ll work diligently to resolve your concerns. We also offer a money-back guarantee, should it be necessary."
                        },
                        {
                            question: "What if my personal Facebook Profile, Pages, or Business Managers get banned?",
                            answer: "No, our services extend far beyond providing agency ad accounts. In addition to ad accounts for platforms like TikTok, Snapchat, and Google, we offer payment solutions, gateways, company formation (LTD, LLC, HK), accounting and tax services, Facebook anti-ban structures, and a variety of other social media services to support your business growth."
                        },
                        {
                            question: "What payment methods does Weberz accept?",
                            answer: "You can obtain as many whitelisted premium agency ad accounts as needed, as long as you are actively spending on them."
                        },
                        {
                            question: "What if I’m not satisfied with the results?",
                            answer: "We accept a wide range of payment methods, including cryptocurrency."
                        },
                        {
                            question: "How much time does it take to start advertising?",
                            answer: "Our process is quick and efficient. You'll receive your ad account within 24 to 48 hours, allowing you to launch your ads promptly."
                        }
                    ].map((faq, index) => (
                        <div key={index} className="transition-all duration-200 border-b border-neutral-400 shadow-b-lg cursor-pointer">
                            <button
                                type="button"
                                className="flex items-center text-neutral-800 justify-between w-full px-4 py-5"
                                onClick={() => handleToggle(index)}
                            >
                                <span className="flex justify-start capitalize text-start text-[13px] md:text-[15px] font-[700]">{faq.question}</span>
                                {
                                    openAnswerIndex === index ?
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                                        </svg>

                                        : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>

                                }

                            </button>
                            <div
                                className={`px-4 text-start text-neutral-800 bg-neutral-200 overflow-hidden transition-max-height duration-500 ease-in-out ${openAnswerIndex === index ? 'max-h-96' : 'max-h-0'
                                    }`}
                            >
                                <p className="py-5">{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <a
                    href="#form"
                    className="text-neutral-800 hover:scale-105 scroll-link uppercase font-[700] leading-[22px] hover:bg-white hover:text-gray-900 text-[17px] flex gap-2 items-center justify-center w-fit border border-neutral-800 py-3 px-10 transition-all duration-300"
                >
                    Contact Us
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </a>
            </div>
        </section>
    );
}

export default Faq;
