import React from 'react'
import photo from '../images/platform.png'


function Hero() {
    return (
        <section className="w-full sectionServices h-[100vh] md:h-[80vh] relative bg-gray-50">
            {/* Overlay */}
            {/* <div class="absolute inset-0 bg-gray-900 z-10 opacity-40"></div> */}
            {/* Background Image */}
            {/* <div class="absolute inset-0 bg-cover bg-center" style="background-image: url('./images/heroImage.webp');">
      </div> */}
            {/* Content */}
            <div className="relative z-10 w-full h-full">

                <div className="flex   w-full h-full justify-center md:flex-row flex-col md:-mt-[40px] mt-[40px] px-2 items-center md:px-64 px-3 ">

                    <div className="flex flex-col w-full md:w-1/2 text-center md:text-start h-full justify-center md:gap-9 gap-2 pb-14 md:pb-0 md:items-start  items-center">
                        <h4 className="font-[800] capitalize leading-[44px] md:leading-[64px] text-[34px] md:text-[58px] text-neutral-800">
                            Boost your business without limits !</h4>


                        <div className="flex md:hidden justify-center w-full md:w-1/2 items-center ">
                            <img src={photo} alt />
                        </div>
                        <p className="font-[400] leading-[36px]  text-[15px] md:text-[18px] text-neutral-800">  Join <b> Weberz Agency</b> for premium services and skyrocket your business! Ideal for those needing stable, downtime-free advertising solutions.



                        </p>
                        <a href="#form" className="text-neutral-800 hover:scale-105  scroll-link uppercase font-[700] leading-[22px] bg-white hover:text-neutral-800  text-[17px] flex gap-2 items-center justify-center w-fit border border-neutral-800 py-3 px-10 transition-all duration-300">
                            work with us
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </a>
                    </div>
                    <div className="md:flex hidden  justify-end w-full md:w-1/2 items-center ">
                        <img src={photo} alt />
                    </div>
                </div>
            </div>






        </section>

    )
}

export default Hero